import React from 'react';

import { Section } from '~/components/section';
import { ReactComponent as Illustration } from '~/static/svg/illustration-404.svg';
import { Container } from '~/theme';

const NotFoundPage = () => (
  <Container>
    <Section>
      <Illustration />
    </Section>
  </Container>
);

export default NotFoundPage;
